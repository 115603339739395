import PropTypes from "prop-types";
import Title from "components/shared/adventures/Title";
import Text from "components/shared/adventures/Text";
import styles from "components/home/Introduction.module.scss";

export default function Introduction({ title, text }) {
  return (
    <div className={styles.component}>
      <Title tag="h2">{title}</Title>
      {text && (
        <Text className={styles.text} size={0}>
          {text}
        </Text>
      )}
    </div>
  );
}

Introduction.propTypes = {
  title: PropTypes.node,
  text: PropTypes.node,
};
