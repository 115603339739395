import Title from "components/shared/adventures/Title";
import Text from "components/shared/adventures/Text";
import Container from "components/shared/adventures/Container";
import styles from "components/home/Testimonials.module.scss";

export default function Testimonials() {
  return (
    <div className={styles.component}>
      <Container className={styles.container}>
        <div>
          <Title tag="h3" className={styles.quote}>
            “There&apos;s nobody else offering this kind of experience.”
          </Title>
          <Text className={styles.author} size={0}>
            — Scott, United States
          </Text>
        </div>
      </Container>
    </div>
  );
}
