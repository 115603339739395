import PropTypes from "prop-types";
import Footer from "components/shared/adventures/Footer";
import Overlays from "components/shared/stays/Overlays";
import ChatButton from "components/shared/stays/ChatButton";
import styles from "components/layouts/LayoutHome.module.scss";

export default function LayoutHome({ children }) {
  return (
    <div className={styles.component}>
      <Overlays />
      {children}
      <Footer wide />
      <ChatButton type="icon-overlay" overlay />
    </div>
  );
}

LayoutHome.propTypes = {
  children: PropTypes.node.isRequired,
};
