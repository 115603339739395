import * as React from "react";

function IconPlay() {
  return (
    <svg width={72} height={72} fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx={36} cy={36} r={36} fill="#fff" />
      <path d="M51.996 36l-24 13.856V22.143l24 13.857z" fill="#0A1200" />
    </svg>
  );
}

export default IconPlay;
