import { Media } from "solar/components/Media";
import IconPlay from "solar/icons/IconPlay";
import IconPlayLg from "solar/icons/IconPlayLg";
import Break from "solar/components/Break";
import Title from "components/shared/adventures/Title";
import VideoAutoPlay from "components/shared/adventures/VideoAutoPlay";
import Photo from "components/home/Photo";
import styles from "components/home/Hero.module.scss";

import photoHeroBackground from "public/home/hero-background.jpg";
import videoPortFirstFrame from "public/home/hero-montage-port.jpg";
import videoLandFirstFrame from "public/home/hero-montage-land.jpg";

export default function Hero() {
  const videoPath = "/home/hero-montage";
  const videoMontage = (
    <VideoAutoPlay
      videoPortFirstFrameSrc={videoPortFirstFrame?.src}
      videoPortMp4={`${videoPath}-port.mp4`}
      videoPortWebm={`${videoPath}-port.webm`}
      videoLandFirstFrameSrc={videoLandFirstFrame?.src}
      videoLandMp4={`${videoPath}-land.mp4`}
      videoLandWebm={`${videoPath}-land.webm`}
      className={styles.foregroundVisual}
      videoAltText="Surf the world like a local"
    />
  );

  return (
    <div className={styles.component}>
      <Photo
        photo={photoHeroBackground}
        priority
        className={styles.backgroundPhoto}
      />
      <div className={styles.foreground}>
        <Title tag="h1" className={styles.title}>
          Surf the world
          <Break />
          like a local
        </Title>
        <Media lessThan="lg">
          <a
            className={styles.videoLink}
            href="https://www.youtube.com/watch?v=_xlKqDsl2Q4&ab_channel=Thermal"
            target="_blank"
            rel="noreferrer"
          >
            {videoMontage}
            <div className={styles.videoPlay}>
              <IconPlay />
            </div>
          </a>
        </Media>
        <Media greaterThan="md">
          <a
            className={styles.videoLink}
            href="https://www.youtube.com/watch?v=idzPtCsHWSA&ab_channel=Thermal"
            target="_blank"
            rel="noreferrer"
          >
            {videoMontage}
            <div className={styles.videoPlay}>
              <IconPlayLg />
            </div>
          </a>
        </Media>
      </div>
    </div>
  );
}
