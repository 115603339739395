import PropTypes from "prop-types";
import styles from "components/shared/adventures/Present.module.scss";

export default function Present({ children }) {
  return <div className={styles.component}>{children}</div>;
}

Present.propTypes = {
  children: PropTypes.node,
};
