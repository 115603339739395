import PropTypes from "prop-types";
import Photo from "components/home/Photo";
import Text from "components/shared/adventures/Text";
import styles from "components/home/Experience.module.scss";

export default function Experience({ name, photo }) {
  return (
    <div className={styles.component}>
      <Photo rounded photo={photo} className={styles.photo} />
      <Text className={styles.name} size={0}>
        {name}
      </Text>
    </div>
  );
}

Experience.propTypes = {
  name: PropTypes.string.isRequired,
  photo: PropTypes.shape({
    src: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    blurDataURL: PropTypes.string,
    blurWidth: PropTypes.number,
    blurHeight: PropTypes.number,
  }).isRequired,
};
