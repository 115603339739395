import PropTypes from "prop-types";
import Link from "next/link";
import { Media } from "solar/components/Media";
import IconPlay from "solar/icons/IconPlay";
import IconPlaySm from "solar/icons/IconPlaySm";
import Photo from "components/home/Photo";
import Title from "components/shared/adventures/Title";
import Text from "components/shared/adventures/Text";
import styles from "components/home/Guide.module.scss";

export default function Guide({ href = "", name, location, photo }) {
  return href ? (
    <Link href={href} target="_blank" prefetch={false} className={styles.card}>
      <Photo rounded photo={photo} className={styles.photo} />
      <div className={styles.overlay} />
      <div className={styles.content}>
        <div className={styles.play}>
          <Media lessThan="lg">
            <IconPlaySm />
          </Media>
          <Media greaterThan="md">
            <IconPlay />
          </Media>
        </div>
        <div className={styles.text}>
          <Title tag="h4" className={styles.name}>
            {name}
          </Title>
          <Text className={styles.location} size={0}>
            {location}
          </Text>
        </div>
      </div>
    </Link>
  ) : (
    <div className={styles.card}>
      <Photo rounded photo={photo} className={styles.photo} />
      <div className={styles.overlay} />
      <div className={styles.content}>
        <div className={styles.text}>
          <Title tag="h4" className={styles.name}>
            {name}
          </Title>
          <Text className={styles.location} size={0}>
            {location}
          </Text>
        </div>
      </div>
    </div>
  );
}

Guide.propTypes = {
  href: PropTypes.string,
  name: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  photo: PropTypes.shape({
    src: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    blurDataURL: PropTypes.string,
    blurWidth: PropTypes.number,
    blurHeight: PropTypes.number,
  }).isRequired,
};
