import Container from "components/shared/adventures/Container";
import Introduction from "components/home/Introduction";
import Guide from "components/home/Guide";
import styles from "components/home/Guides.module.scss";

import photoGuidePablo from "public/home/guides/pablo-narvaez-oaxaca.jpg";
import photoGuideMaykol from "public/home/guides/maykol-espinoza-osa.jpg";
import photoGuideEddie from "public/home/guides/eddie-salazar-galapagos.jpg";
import photoGuideAlan from "public/home/guides/alan-burke-barbados.jpg";
import photoGuideMaryam from "public/home/guides/maryam-el-gardoum-taghazout.jpg";
import photoGuideSuri from "public/home/guides/suri.jpg";

export default function Guides() {
  return (
    <div className={styles.component}>
      <Container>
        <Introduction
          title={
            <>
              Discover surfing’s
              <br />
              best-kept secret
            </>
          }
          text="Behind every iconic surf film is a local guide making it all happen. These men and women are the key to making our adventures truly unforgettable."
        />
        <div className={styles.grid}>
          <Guide
            href="https://www.youtube.com/watch?v=qNuPngPrdfc&ab_channel=Thermal"
            name="Pablo Narvaez"
            location="Oaxaca, Mexico"
            photo={photoGuidePablo}
          />
          <Guide
            href="https://www.youtube.com/watch?v=iJIvgpVppGE&ab_channel=Thermal"
            name="Maryam el Gardoum"
            location="Taghazout, Morocco"
            photo={photoGuideMaryam}
          />
          <Guide
            href="https://www.youtube.com/watch?v=g1AFiA7S1BA&ab_channel=Thermal"
            name="Eddie Salazar"
            location="Galapagos Islands, Ecuador"
            photo={photoGuideEddie}
          />
          <Guide
            href="https://www.youtube.com/watch?v=eEreSqOIa4E&ab_channel=Thermal"
            name="Alan Burke"
            location="Barbados"
            photo={photoGuideAlan}
          />
          <Guide
            href="https://www.youtube.com/watch?v=Nn7PPUIFg1s&ab_channel=Thermal"
            name="Mayköl Espinoza"
            location="Osa, Costa Rica"
            photo={photoGuideMaykol}
          />
          <Guide
            href="https://www.youtube.com/watch?v=yw48IEZv54c&ab_channel=Thermal"
            name="Suri Barrera"
            location="La Libertad, El Salvador"
            photo={photoGuideSuri}
          />
        </div>
      </Container>
    </div>
  );
}
