import PropTypes from "prop-types";
import cn from "classnames";
import Image from "next/image";
import styles from "components/home/Photo.module.scss";

export default function Photo({
  photo,
  alt = "",
  priority = false,
  placeholder = "blur",
  rounded = false,
  className = null,
}) {
  return (
    <Image
      src={photo}
      alt={alt}
      priority={priority}
      placeholder={placeholder}
      className={cn(styles.component, rounded && styles.rounded, className)}
    />
  );
}

Photo.propTypes = {
  photo: PropTypes.shape({
    src: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    blurDataURL: PropTypes.string,
    blurWidth: PropTypes.number,
    blurHeight: PropTypes.number,
  }).isRequired,
  alt: PropTypes.string,
  priority: PropTypes.bool,
  placeholder: PropTypes.string,
  rounded: PropTypes.bool,
  className: PropTypes.node,
};
