import * as React from "react";

function iconPlaySm() {
  return (
    <svg width={46} height={46} fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx={23} cy={23} r={23} fill="#fff" />
      <path d="M33 23l-15 8.66V14.34L33 23z" fill="#0A1200" />
    </svg>
  );
}

export default iconPlaySm;
