/* eslint-disable no-nested-ternary */

import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import { useInView } from "react-intersection-observer";
import Image from "next/image";
import styles from "components/shared/adventures/VideoAutoPlay.module.scss";

export default function VideoAutoPlay({
  videoPortFirstFrameSrc = "",
  videoPortMp4 = "",
  videoPortWebm = "",
  videoLandFirstFrameSrc = "",
  videoLandMp4 = "",
  videoLandWebm = "",
  videoAltText = "Thermal",
  lazyLoad = false,
  className = null,
}) {
  const videoLandRef = useRef(null);
  const videoPortRef = useRef(null);
  const videoSourceWebm = useRef(null);
  const videoSourceMp4 = useRef(null);
  const [isActive, setIsActive] = useState(false);
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const [greaterThanLg, setGreaterThanLg] = useState(null);
  const [currentVideoRef, setCurrentVideoRef] = useState(null);
  const [wrapperRef, wrapperInView] = useInView({
    threshold: 0.6,
  });

  function init() {
    // delay immediately showing the video
    setTimeout(() => {
      setIsActive(true);
    }, 1000);
  }

  // get viewport size
  useEffect(() => {
    setGreaterThanLg(window.innerWidth > styles.solBreakpointLg);
  }, []);

  // set video ref based on viewport size
  useEffect(() => {
    setCurrentVideoRef(greaterThanLg ? videoLandRef : videoPortRef);
  }, [greaterThanLg]);

  // initialize immediately if not lazyloading
  useEffect(() => {
    if (!lazyLoad) {
      init();
    }
  }, [lazyLoad, currentVideoRef]);

  // register listeners for when video is loaded
  useEffect(() => {
    const videoEl = currentVideoRef?.current;

    if (videoEl) {
      videoEl.addEventListener("timeupdate", (event) => {
        if (event.timeStamp > 0) {
          // delay showing the video because safari object-fit bug causes flash of unsized content
          setTimeout(() => {
            setIsVideoLoaded(true);
          }, 200);
        }
      });
    }

    return () => {
      if (videoEl) {
        videoEl.removeEventListener("timeupdate", null);
      }
    };
  }, [currentVideoRef]);

  // initialize after entering viewport if lazyloading
  useEffect(() => {
    if (lazyLoad && !isVideoLoaded && wrapperInView) {
      if (videoSourceMp4.current && videoSourceWebm.current) {
        videoSourceMp4.current.src =
          !greaterThanLg && videoPortMp4 ? videoPortMp4 : videoLandMp4;
        videoSourceWebm.current.src =
          !greaterThanLg && videoPortWebm ? videoPortWebm : videoLandWebm;
        currentVideoRef?.current.load();
      }
      init();
    }
  }, [
    wrapperInView,
    isVideoLoaded,
    lazyLoad,
    videoPortMp4,
    videoPortWebm,
    videoLandMp4,
    videoLandWebm,
    currentVideoRef,
    greaterThanLg,
  ]);

  return (
    <div
      ref={wrapperRef}
      className={cn(className, greaterThanLg === null && styles.widthUnknown)}
    >
      <div className={styles.imgWrapper}>
        <Image
          src={
            greaterThanLg
              ? videoLandFirstFrameSrc || videoPortFirstFrameSrc
              : videoPortFirstFrameSrc || videoLandFirstFrameSrc
          }
          priority
          sizes="16px"
          placeholder="empty"
          fill
          alt={videoAltText}
          className={cn(
            styles.visual,
            styles.lqip,
            isVideoLoaded && isActive && styles.hidden
          )}
        />
      </div>

      {greaterThanLg !== null && (
        <video
          ref={greaterThanLg ? videoLandRef : videoPortRef}
          preload="auto"
          muted
          loop
          autoPlay
          playsInline
          poster="/shared/transparent-square.png"
          className={cn(
            styles.visual,
            (!isVideoLoaded || !isActive) && styles.hidden
          )}
        >
          <source
            ref={videoSourceWebm}
            src={
              lazyLoad
                ? ""
                : !greaterThanLg && videoPortWebm
                ? videoPortWebm
                : videoLandWebm
            }
            type="video/webm"
          />
          <source
            ref={videoSourceMp4}
            src={
              lazyLoad
                ? ""
                : !greaterThanLg && videoPortMp4
                ? videoPortMp4
                : videoLandMp4
            }
            type="video/mp4"
          />
        </video>
      )}
    </div>
  );
}

VideoAutoPlay.propTypes = {
  videoPortFirstFrameSrc: PropTypes.string,
  videoPortMp4: PropTypes.string,
  videoPortWebm: PropTypes.string,
  videoLandFirstFrameSrc: PropTypes.string,
  videoLandMp4: PropTypes.string,
  videoLandWebm: PropTypes.string,
  videoAltText: PropTypes.string,
  lazyLoad: PropTypes.bool,
  className: PropTypes.node,
};
