import * as React from "react";

function IconPlayLg() {
  return (
    <svg
      width={144}
      height={144}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx={72} cy={72} r={72} fill="#fff" />
      <path
        d="M104.547 71.04L55.38 95.981V46.1l49.167 24.941z"
        fill="#0A1200"
      />
    </svg>
  );
}

export default IconPlayLg;
