import cn from "classnames";
import Break from "solar/components/Break";
import Container from "components/shared/adventures/Container";
import Title from "components/shared/adventures/Title";
import Text from "components/shared/adventures/Text";
import VideoAutoPlay from "components/shared/adventures/VideoAutoPlay";
import Introduction from "components/home/Introduction";
import styles from "components/home/Surf.module.scss";

import videoPortFirstFrame from "public/home/surf-montage-port.jpg";
import videoLandFirstFrame from "public/home/surf-montage-land.jpg";

export default function Surf() {
  const videoPath = "/home/surf-montage";

  return (
    <div className={styles.component}>
      <Container className={styles.container}>
        <Introduction
          title={
            <>
              More surfing,
              <br />
              less guesswork
            </>
          }
        />
        <div className={styles.media}>
          <VideoAutoPlay
            videoPortFirstFrameSrc={videoPortFirstFrame?.src}
            videoPortMp4={`${videoPath}-port.mp4`}
            videoPortWebm={`${videoPath}-port.webm`}
            videoLandFirstFrameSrc={videoLandFirstFrame?.src}
            videoLandMp4={`${videoPath}-land.mp4`}
            videoLandWebm={`${videoPath}-land.webm`}
            videoAltText="More surfing, less guesswork"
            lazyLoad
          />
        </div>
        <div className={styles.grid}>
          <div className={styles.gridRow}>
            <div className={cn(styles.gridItem, styles.gridItem1)}>
              <Title tag="h3">
                Access
                <Break />
                unknown spots
              </Title>
              <Text className={styles.description} size={0}>
                No surf cam? No problem. With our guides you can expect to surf
                waves that aren&apos;t on everyone&apos;s radar.
              </Text>
            </div>
            <div className={styles.gridColumnDivider} />
            <div className={cn(styles.gridItem, styles.gridItem2)}>
              <Title tag="h3">
                Maximize
                <Break />
                your time
              </Title>
              <Text className={styles.description} size={0}>
                Our guides make sure you spend less time trying to pick the spot
                and dial it in, and more time scoring.
              </Text>
            </div>
          </div>
          <div className={styles.gridRowDivider} />
          <div className={styles.gridRow}>
            <div className={cn(styles.gridItem, styles.gridItem3)}>
              <Title tag="h3">
                Surf your
                <Break />
                perfect wave
              </Title>
              <Text className={styles.description} size={0}>
                Your guide is there to make sure you&apos;re on the right wave,
                whether you&apos;re a pro or on your very first surf trip.
              </Text>
            </div>
            <div className={styles.gridColumnDivider} />
            <div className={cn(styles.gridItem, styles.gridItem4)}>
              <Title tag="h3">
                Leave the
                <Break />
                phone behind
              </Title>
              <Text className={styles.description} size={0}>
                Forecasts aren&apos;t foolproof. Lean on your guide&apos;s
                decades of experience to find the best waves as conditions
                change.
              </Text>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
