import Link from "next/link";
import VideoAutoPlay from "components/shared/adventures/VideoAutoPlay";
import Container from "components/shared/adventures/Container";
import styles from "components/home/Instruction.module.scss";
import videoLandFirstFrame from "public/home/instruction-montage-land.jpg";
import Introduction from "components/home/Introduction";

export default function Instruction() {
  return (
    <div className={styles.component}>
      <Container className={styles.container}>
        <Introduction
          title={<>Just starting out?</>}
          text="Our Surfer Development Program puts you on the fast track to the next level thanks to in-water coaching, video analysis, and a 2:1 student-to-teacher ratio."
        />
        <Link href="/surfer-development" className={styles.visual}>
          <VideoAutoPlay
            videoLandFirstFrameSrc={videoLandFirstFrame?.src}
            videoLandMp4="/home/instruction-montage-land.mp4"
            videoLandWebm="/home/instruction-montage-land.webm"
            className={styles.videoPlayer}
          />
          <div className={styles.button}>See full details</div>
        </Link>
      </Container>
    </div>
  );
}
