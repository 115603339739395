import { Swiper, SwiperSlide } from "swiper/react";
import Container from "components/shared/adventures/Container";
import Introduction from "components/home/Introduction";
import Experience from "components/home/Experience";
import styles from "components/home/Experiences.module.scss";

import photoExperienceBee from "public/home/experience-bee.jpg";
import photoExperienceHistory from "public/home/experience-history.jpg";
import photoExperienceCooking from "public/home/experience-cooking.jpg";
import photoExperienceLava from "public/home/experience-lava.jpg";
import { Media } from "solar/components/Media";

export default function Experiences() {
  const experiences = [
    {
      id: 1,
      photo: photoExperienceBee,
      caption: "Experience sustainable farming in Barbados",
    },
    {
      id: 2,
      photo: photoExperienceCooking,
      caption: "Taste regional dishes cooked over an open-flame in Oaxaca",
    },
    {
      id: 3,
      photo: photoExperienceHistory,
      caption: "Ride historic Hawaiian boards on the North Shore",
    },
    {
      id: 4,
      photo: photoExperienceLava,
      caption: "Explore remote corners of the Galapagos",
    },
  ];

  return (
    <div className={styles.component}>
      <Container paddingX={false}>
        <Container paddingY={false}>
          <Introduction
            title={
              <>
                Say goodbye to
                <br />
                tourist traps
              </>
            }
            text="Let your personal surf guide welcome you into their world. Experience an authentic side of local life few surfers ever see."
          />
        </Container>
        <div className={styles.visuals}>
          <Media lessThan="lg">
            <div className={styles.overrides}>
              <Swiper
                slidesPerView={1.3}
                cssMode
                watchOverflow
                simulateTouch={false}
              >
                <SwiperSlide className={styles.slide}>
                  <Experience
                    photo={experiences[0].photo}
                    name={experiences[0].caption}
                  />
                </SwiperSlide>
                <SwiperSlide className={styles.slide}>
                  <Experience
                    photo={experiences[2].photo}
                    name={experiences[2].caption}
                  />
                </SwiperSlide>
                <SwiperSlide className={styles.slide}>
                  <Experience
                    photo={experiences[3].photo}
                    name={experiences[3].caption}
                  />
                </SwiperSlide>
                <SwiperSlide className={styles.slide}>
                  <Experience
                    photo={experiences[1].photo}
                    name={experiences[1].caption}
                  />
                </SwiperSlide>
              </Swiper>
            </div>
          </Media>
          <Media greaterThan="md">
            <Container paddingY={false} className={styles.grid}>
              <div className={styles.gridRow}>
                <Experience
                  photo={experiences[0].photo}
                  name={experiences[0].caption}
                />
                <Experience
                  photo={experiences[1].photo}
                  name={experiences[1].caption}
                />
              </div>
              <div className={styles.gridRow}>
                <Experience
                  photo={experiences[2].photo}
                  name={experiences[2].caption}
                />
                <Experience
                  photo={experiences[3].photo}
                  name={experiences[3].caption}
                />
              </div>
            </Container>
          </Media>
        </div>
      </Container>
    </div>
  );
}
