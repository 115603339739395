import PropTypes from "prop-types";
import { getAdventures } from "lib/api";
import LayoutHome from "components/layouts/LayoutHome";
import Meta from "solar/components/Meta";
import Navigation from "components/shared/adventures/Navigation";
import Present from "components/shared/adventures/Present";
import Hero from "components/home/Hero";
import Adventures from "components/home/Adventures";
import Testimonials from "components/home/Testimonials";
import Guides from "components/home/Guides";
import Surf from "components/home/Surf";
import Experiences from "components/home/Experiences";
import Accommodations from "components/home/Accommodations";
import Instruction from "components/home/Instruction";
import BannerPhone from "components/shared/adventures/BannerPhone";

export default function Home({ adventures }) {
  const adventuresHomeCards = adventures
    .filter((adventure) => adventure.home_position)
    .sort((a, b) => a.home_position - b.home_position);

  return (
    <>
      <Meta
        title="Private, guided surf adventures"
        description="The world's most experienced local guides, one-of-a-kind experiences, and hand-picked lodging with soul."
        path="/"
        imageOgUrl="https://www.thermal.travel/meta/home-og.jpg"
        imageTwitterUrl="https://www.thermal.travel/meta/home-twitter.jpg"
        imageType="image/jpeg"
      />
      <BannerPhone />
      <Present>
        <Navigation
          positionAbsolute
          formSource="home"
          adventures={adventures}
        />
        <Hero />
      </Present>
      <Adventures adventures={adventuresHomeCards} />
      <Instruction />
      <Guides />
      <Surf />
      <Experiences />
      <Accommodations />
      <Testimonials />
    </>
  );
}

export async function getStaticProps() {
  const response = await getAdventures();
  if (response.errorMsg) {
    return { props: {} };
  }
  return {
    props: {
      adventures: response.filter((adventure) => adventure.is_visible === true),
    },
    revalidate: 1800,
  };
}

Home.Layout = LayoutHome;

Home.propTypes = {
  adventures: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      country: PropTypes.string.isRequired,
      hero_card: PropTypes.shape({
        url: PropTypes.string,
        content_type: PropTypes.string,
        byte_size: PropTypes.number,
        width: PropTypes.number,
        height: PropTypes.number,
      }),
      hero_montage_first_frame_port: PropTypes.shape({
        url: PropTypes.string,
        content_type: PropTypes.string,
        byte_size: PropTypes.number,
        width: PropTypes.number,
        height: PropTypes.number,
      }),
      videoMp4: PropTypes.shape({
        url: PropTypes.string,
        content_type: PropTypes.string,
        byte_size: PropTypes.number,
      }),
      videoWebm: PropTypes.shape({
        url: PropTypes.string,
        content_type: PropTypes.string,
        byte_size: PropTypes.number,
      }),
    })
  ),
};
