import cn from "classnames";
import PropTypes from "prop-types";
import React, { useState, useRef, useMemo } from "react";
import { parseAdventureName } from "helpers/naming";
import { useMediaQuery } from "react-responsive";
import { VideoCardContext } from "context/video-card-context";
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import useSwiperRef from "helpers/hooks/useSwiperRef";
import Link from "next/link";
import IconArrowSubmit from "solar/icons/IconArrowSubmit";
import Break from "solar/components/Break";
import Container from "components/shared/adventures/Container";
import Introduction from "components/home/Introduction";
import VideoCardAdventure from "components/shared/adventures/VideoCardAdventure";
import styles from "components/home/Adventures.module.scss";

export default function Adventures({ adventures }) {
  const breakpointLg = parseInt(styles.solBreakpointLg, 10);
  const greaterThanBreakpoint = useMediaQuery({
    query: `(min-width: ${breakpointLg}px)`,
  });

  const sizeSwiperGapSm = parseInt(styles.sizeSwiperGapSm, 10);
  const sizeSwiperGapLg = parseInt(styles.sizeSwiperGapLg, 10);

  const [nextEl, nextElRef] = useSwiperRef();
  const [prevEl, prevElRef] = useSwiperRef();

  const [activeVideo, setActiveVideo] = useState(null);
  const [currentSlide, setCurrentSlide] = useState(1);
  const listenersActive = useRef(null);
  listenersActive.current = true;

  function updateSlidePosition(pos) {
    // throttle calls made to this function to prevent duplicates when both the onSlideChange and onNavigationNext fire
    if (listenersActive.current === true) {
      setCurrentSlide(pos);
      listenersActive.current = false;

      // reactivate the function
      setTimeout(() => {
        listenersActive.current = true;
      }, 50);
    }
  }

  return (
    <div className={styles.component}>
      <VideoCardContext.Provider
        value={useMemo(
          () => ({ activeVideo, setActiveVideo }),
          [activeVideo, setActiveVideo]
        )}
      >
        <Container paddingY={false}>
          <Introduction
            title={
              <>
                Private, guided
                <Break />
                surf adventures
              </>
            }
            text="The world’s most respected local guides, one-of-a-kind authentic experiences, and hand-picked surf lodging with soul."
          />
        </Container>
        <div className={styles.carousel}>
          <button
            type="button"
            ref={prevElRef}
            className={cn(styles.arrow, styles.previous)}
          >
            <IconArrowSubmit />
          </button>
          <button
            type="button"
            ref={nextElRef}
            className={cn(styles.arrow, styles.next)}
          >
            <IconArrowSubmit />
          </button>
          <div className={styles.overrides}>
            <Swiper
              watchOverflow
              centeredSlides
              centeredSlidesBounds
              breakpoints={{
                0: {
                  slidesPerView: 1.2,
                  spaceBetween: sizeSwiperGapSm,
                  initialSlide: 1,
                },
                [breakpointLg]: {
                  slidesPerView: 2.6,
                  spaceBetween: sizeSwiperGapLg,
                  initialSlide: 1,
                },
              }}
              cssMode={true && !greaterThanBreakpoint}
              modules={[Navigation]}
              simulateTouch={false}
              navigation={{
                prevEl,
                nextEl,
                lockClass: styles.lock,
                disabledClass: styles.disabled,
              }}
              onSlideChange={(e) => {
                updateSlidePosition(e.activeIndex);
              }}
              onNavigationNext={() => {
                updateSlidePosition(currentSlide + 1);
              }}
              onNavigationPrev={() => {
                updateSlidePosition(currentSlide - 1);
              }}
            >
              {adventures.map((adventure, idx) => (
                <SwiperSlide className={styles.slide} key={adventure.id}>
                  <VideoCardAdventure
                    slug={adventure.slug}
                    name={parseAdventureName(adventure.name)}
                    country={adventure.country}
                    photoCover={adventure.hero_card}
                    photoFirstFrame={adventure.hero_montage_first_frame_port}
                    videoMp4={adventure.hero_montage_port_mp4}
                    videoWebm={adventure.hero_montage_port_webm}
                    activeVideo={activeVideo}
                    setActiveVideo={setActiveVideo}
                    makeActive={currentSlide === idx}
                    pauseOnMouseOut={false}
                    defaultPlaying={adventure.home_position === 2}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
        <Container paddingY={false} className={styles.footer}>
          <Link prefetch={false} href="/adventures" className={styles.button}>
            Explore your next adventure
          </Link>
        </Container>
      </VideoCardContext.Provider>
    </div>
  );
}

Adventures.propTypes = {
  adventures: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      country: PropTypes.string.isRequired,
      hero_card: PropTypes.shape({
        url: PropTypes.string,
        content_type: PropTypes.string,
        byte_size: PropTypes.number,
        width: PropTypes.number,
        height: PropTypes.number,
      }),
      hero_montage_first_frame_port: PropTypes.shape({
        url: PropTypes.string,
        content_type: PropTypes.string,
        byte_size: PropTypes.number,
        width: PropTypes.number,
        height: PropTypes.number,
      }),
      videoMp4: PropTypes.shape({
        url: PropTypes.string,
        content_type: PropTypes.string,
        byte_size: PropTypes.number,
      }),
      videoWebm: PropTypes.shape({
        url: PropTypes.string,
        content_type: PropTypes.string,
        byte_size: PropTypes.number,
      }),
    })
  ),
};
