import cn from "classnames";
import Container from "components/shared/adventures/Container";
import Introduction from "components/home/Introduction";
import Photo from "components/home/Photo";
import styles from "components/home/Accommodations.module.scss";

import photoAccommodation1 from "public/home/accommodation-1.jpg";
import photoAccommodation2 from "public/home/accommodation-2.jpg";
import photoAccommodation3 from "public/home/accommodation-3.jpg";
import photoAccommodation4 from "public/home/accommodation-4.jpg";

export default function Accommodations() {
  return (
    <div className={styles.component}>
      <Container>
        <Introduction
          title={
            <>
              Hand-picked villas
              <br />
              and boutique hotels
            </>
          }
          text="Our trips include stays at soulful, locally-owned villas and boutique hotels — chosen for their proximity to the surf, luxurious amenities and relaxed local style."
        />
        <div className={styles.grid}>
          <div className={cn(styles.gridRow, styles.gridRow1)}>
            <Photo
              rounded
              photo={photoAccommodation1}
              className={styles.photo}
            />
            <Photo
              rounded
              photo={photoAccommodation2}
              className={styles.photo}
            />
          </div>
          <div className={cn(styles.gridRow, styles.gridRow2)}>
            <Photo
              rounded
              photo={photoAccommodation3}
              className={styles.photo}
            />
            <Photo
              rounded
              photo={photoAccommodation4}
              className={styles.photo}
            />
          </div>
        </div>
      </Container>
    </div>
  );
}
